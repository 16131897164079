import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useLookupCountryCountryIdSubdivisionGet,
  useLookupCountryGet,
  useProfileGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { FormDatePicker } from "@components/FormDatePicker";
import convertUTCDateToLocalDate from "@utils/convertUtcToLocal";
import { InfoIcon } from "@components/Icons";
import { Typography } from "@mui/material";
import { GENDERS, SUFFIX_OPTIONS } from "@utils/constants";
import parsePhoneNumberFromString from "libphonenumber-js";
export const Profile = () => {
  const { data: countriesResponse } = useLookupCountryGet({
    query: {
      staleTime: Infinity
    }
  });
  const countries = useMemo(() => countriesResponse?.data, [countriesResponse]);
  const { data: userResponse, isFetching: isFetchingUser } = useProfileGet();
  const user = useMemo(() => userResponse?.data, [userResponse]);
  const { control, reset, watch, getValues } = useForm({
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      suffix: "",
      email: "",
      gender: "",
      country: "",
      locality: "",
      address1: "",
      address2: "",
      postalCode: "",
      phone: "",
      birthedAt: "",
      photoUrl: ""
    }
  });
  useEffect(() => {
    if (!countries || !user) return;

    const dialCode = countries?.find(
      (country) => country?.countryId === user?.person?.addressPrimary?.country
    )?.dialCode;

    const defaultValues = {
      firstName: user?.person?.firstName,
      middleName: user?.person?.middleName,
      lastName: user?.person?.lastName,
      suffix: user?.person?.suffix,
      email: user?.email,
      country: user?.person?.addressPrimary?.country,
      dialCode,
      province: user?.person?.addressPrimary?.province,
      locality: user?.person?.addressPrimary?.locality,
      address1: user?.person?.addressPrimary?.lines?.[0],
      address2: user?.person?.addressPrimary?.lines?.[1],
      postalCode: user?.person?.addressPrimary?.postalCode,
      phone: user?.phone,
      gender: user?.person?.gender,
      birthedAt: user?.person?.birthedAt
        ? convertUTCDateToLocalDate(new Date(user?.person?.birthedAt))
        : "",
      photoUrl: user?.person?.avatar?.baseUrl
        ? user?.person?.avatar?.baseUrl + user?.person?.avatar?.path
        : undefined
    };
    reset(defaultValues, {
      keepDirtyValues: false
    });
  }, [countries, user]);
  const { country } = watch();
  const { data: provinces } = useLookupCountryCountryIdSubdivisionGet(
    country as string,
    {
      query: {
        enabled: !!country
      }
    }
  );

  const provincesOptions = useMemo(() => {
    if (provinces) {
      return provinces.data.map((province) => ({
        value: province.subdivisionId,
        label: province.name
      }));
    }
    return [];
  }, [provinces]);
  const countryOptions = useMemo(() => {
    if (countries) {
      return countries.map((country) => ({
        value: country.countryId,
        label: country.name
      }));
    }
    return [];
  }, [countries]);
  return (
    <Container>
      <Toolbar title={"Account Profile"} />
      <Form data-testid="profile-form">
        <Loader isLoading={isFetchingUser}>
          <Container>
            <Grid
              sx={{
                display: "flex",
                background: "#F5F5F5",
                borderRadius: "6px",
                padding: "12px"
              }}
            >
              <InfoIcon sx={{ marginRight: "8px" }} />
              <Typography>
                To fully view/edit your profile, please use the SportsGravy
                Mobile App
              </Typography>
            </Grid>
          </Container>
          <Grid sx={{ marginTop: "5px" }} container spacing={3}>
            <Grid xs={6}>
              <FormInput
                name="photoUrl"
                control={control}
                label="Avatar"
                type="avatar"
                disabled
              />
            </Grid>
          </Grid>
          <Grid sx={{ marginTop: "24px" }} container spacing={3}>
            <Grid data-testid="profile-firstName" xs={6}>
              <FormInput
                name="firstName"
                control={control}
                label="First Name"
                type="text"
                disabled
              />
            </Grid>
            <Grid data-testid="profile-middleName" xs={6}>
              <FormInput
                name="middleName"
                control={control}
                label="Middle Name"
                type="text"
                disabled
              />
            </Grid>
            <Grid data-testid="profile-lastName" xs={6}>
              <FormInput
                name="lastName"
                control={control}
                label="Last Name"
                type="text"
                disabled
              />
            </Grid>
            <Grid data-testid="profile-suffix" xs={6}>
              <FormSelect
                name="suffix"
                control={control}
                label="Suffix"
                options={SUFFIX_OPTIONS}
                disabled
              />
            </Grid>

            <Grid data-testid="profile-dob" xs={6}>
              <FormDatePicker
                name="birthedAt"
                control={control}
                label="Date of Birth"
                disabled
              />
            </Grid>
            <Grid data-testid="profile-gender" xs={6}>
              <FormSelect
                name="gender"
                control={control}
                label="Gender"
                options={GENDERS}
                disabled
              />
            </Grid>
            <Grid data-testid="profile-country" xs={6}>
              <FormSelect
                name="country"
                control={control}
                options={countryOptions}
                label="Country"
                disabled
              />
            </Grid>
            <Grid data-testid="profile-address1" xs={6}>
              <FormInput
                type="text"
                name="address1"
                control={control}
                label="Address Line 1"
                disabled
              />
            </Grid>
            <Grid data-testid="profile-address2" xs={6}>
              <FormInput
                name="address2"
                control={control}
                label="Address Line 2"
                type="text"
                disabled
              />
            </Grid>
            <Grid data-testid="profile-locality" xs={6}>
              <FormInput
                name="locality"
                control={control}
                label="City"
                type="text"
                disabled
              />
            </Grid>
            <Grid data-testid="profile-province" xs={6}>
              <FormSelect
                name="province"
                control={control}
                label="State"
                options={provincesOptions}
                type="text"
                disabled
              />
            </Grid>
            <Grid data-testid="profile-zipCode" xs={6}>
              <FormInput
                name="postalCode"
                control={control}
                label="Zip Code"
                type="text"
                disabled
              />
            </Grid>
            <Grid data-testid="profile-phone" xs={6}>
              <FormInput
                name="phone"
                type="tel"
                label="Mobile Phone"
                control={control}
                disabled
                countryCode={
                  getValues("phone")
                    ? parsePhoneNumberFromString(getValues("phone") as string)
                        ?.countryCallingCode
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </Loader>
      </Form>
    </Container>
  );
};
