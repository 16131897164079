import {
  ModelRole,
  ModelRolePermission,
  RoleAlias,
  TeamWithOrgCreateInput,
  useAdminLevelGet,
  useAdminPermissionGet,
  useAdminPositionGet,
  useAdminRoleRoleIdUserGet,
  useAdminSeasonGet,
  useAdminSubroleGet,
  useAdminTeamTeamIdGet,
  useAdminTeamTeamIdPatch,
  ModelPerson,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useState, SyntheticEvent, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../../recoil/auth";
import { useForm } from "react-hook-form";
import {
  CoachOption,
  CoachSelectionForm
} from "../components/CoachSelectionForm";
import {
  ManagerOption,
  ManagerSelectionForm
} from "../components/ManagerSelectionForm";
import {
  PlayerOption,
  PlayerSelectionForm
} from "../components/PlayerSelectionForm";
import { Grid } from "@mui/material";
import { Loader } from "../../../components/crud/Loader";
import { PermissionSelectionForm } from "../components/PermissionSelectionForm";
import { TeamDetailsForm } from "./TeamDetailsForm";
import { Toolbar } from "../../../components/crud/Toolbar";
import { useApiSelectOptions } from "../../../hooks/useApiSelectOptions";
import { Container } from "../../../components/crud/Container";
import { Footer } from "../../../components/crud/Footer";
import { Button } from "../../../components/Button";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { cleanObject } from "../../../utils/cleanObject";
import { enqueueSnackbar } from "notistack";
import Sentry from "../../../services/Sentry";
import { Form } from "@components/crud/Form";

export const TeamEdit = () => {
  const navigate = useNavigate();
  const { teamId } = useParams();

  const organizationId = useRecoilValue(organizationAtom);

  if (!organizationId) {
    navigate("/organizations");
  }

  const [tab, setTab] = useState("Team Details");
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    if (form.formState.isValid) setTab(value as string);
  };
  const {
    data: teamResponse,
    isLoading: isFetchingTeam,
    error: error
  } = useAdminTeamTeamIdGet(teamId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const [sportId, setSportId] = useState<string | undefined>(undefined);
  const [seasonId, setSeasonId] = useState<string | undefined>(undefined);
  const [players, setPlayers] = useState<string[]>([]);
  const [playersWithValues, setPlayersWithValues] = useState<PlayerOption[]>(
    []
  );
  const [coaches, setCoaches] = useState<string[]>([]);
  const [managers, setManagers] = useState<string[]>([]);
  const [coachesWithValues, setCoachesWithValues] = useState<CoachOption[]>([]);
  const [managerWithValues, setManagerWithValues] = useState<ManagerOption[]>(
    []
  );

  const { data: permissionRequest } = useAdminPermissionGet();

  const permissionsList = useMemo(() => {
    if (permissionRequest?.data) {
      const children = permissionRequest.data.find(
        (permission) => permission.permissionId === "team"
      )?.children;
      return (
        children?.filter(
          (permission) => permission.permissionId?.includes("associated")
        ) || []
      );
    }
    return [];
  }, [permissionRequest]);

  const { data: playerUserOptions, isLoading: playerUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.PLAYER, {
      seasonId: seasonId,
      sportId: sportId,
      type: "TEAM",
      organizationId: organizationId
    });

  const [playerOptionsList, setPlayerOptionsList] = useState<ModelPerson[]>([]);

  useEffect(() => {
    if (playerUserOptions) {
      setPlayerOptionsList(playerUserOptions.data);
    }
  }, [playerUserOptions]);
  const { data: coachUserOptions } = useAdminRoleRoleIdUserGet(
    RoleAlias.COACH,
    {
      organizationId: organizationId
    }
  );

  const [coachOptionsList, setCoachOptionsList] = useState<ModelPerson[]>([]);

  useEffect(() => {
    if (coachUserOptions) {
      setCoachOptionsList(coachUserOptions.data);
    }
  }, [coachUserOptions]);

  const { data: managerUserOptions, isLoading: managerUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.MANAGER, {
      organizationId: organizationId
    });

  const [managerOptionsList, setManagerOptionsList] = useState<ModelPerson[]>(
    []
  );

  useEffect(() => {
    if (managerUserOptions) {
      setManagerOptionsList(managerUserOptions.data);
    }
  }, [managerUserOptions]);

  const { data: subRoleOptions } = useAdminSubroleGet({
    pageSize: "100",
    organizationId: organizationId,
    includePermissions: true
  });

  const team = useMemo(() => {
    if (teamResponse) {
      return teamResponse.data;
    }
    return null;
  }, [teamResponse]);

  const teamRoles = useMemo(() => {
    if (teamResponse) {
      return teamResponse.data.roles || [];
    }
    return [];
  }, [teamResponse]);
  const form = useForm({
    mode: "onBlur"
  });
  const { control, reset, handleSubmit } = form;

  const getCoachesOrManagers = (alias) => {
    if (!team) return;
    const invitedUsers = team.invites?.filter(
      (item) => item.invitedFor![0]?.alias === alias
    );
    const activeUsers = team.userRoles?.filter(
      (item) => item.role?.alias === alias
    );
    let invites: CoachOption[] = [],
      accepted: CoachOption[] = [];

    if (activeUsers) {
      accepted = activeUsers.map((user) => {
        const formattedPerson = {
          label: `${user?.user?.person?.firstName} ${user?.user?.person?.lastName}`,
          id: user!.user!.personId!,
          role: alias,
          personId: user!.user!.personId!,
          value: user!.user!.personId!,
          roleId: user.roleId!,
          ...user,
          permissions: user?.permissionOverrides,
          status: "ACTIVE"
        };
        return formattedPerson;
      });
      console.log("COACH?MANAGERS_ACCEPTED:", accepted);
    }
    if (invitedUsers) {
      invites = invitedUsers
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((user) => {
          const formattedPerson = {
            label: `${user?.person?.firstName} ${user?.person?.lastName}`,
            id: user!.person!.personId!,
            value: user!.person!.personId!,
            personId: user!.person!.personId!,
            role: alias,
            roleId: user.invitedFor![0].roleId!,
            ...user,
            status: "PENDING",
            permissions: Object.keys(user.metadata as object).includes(
              "permissions"
            )
              ? (user.metadata as object)["permissions"]
              : []
          };
          return formattedPerson;
        });
      console.log("COACH/MANAGERS_INVITES:", invites);
    }
    return [...invites, ...accepted];
  };
  const getPlayers = () => {
    if (!team) return;
    const selectedPlayersInvite = team.invites?.filter(
      (item) => item.invitedFor![0]?.alias === "PLAYER"
    );
    const addedPlayers = team.userRoles?.filter(
      (item) => item.role?.alias === "PLAYER"
    );

    const getParentObject = (parentId) => {
      const invitedParent = team.invites?.find(
        (invite) => invite?.personId === parentId
      );
      if (invitedParent)
        return {
          id: invitedParent.personId!,
          personId: invitedParent.personId!,
          value: invitedParent.personId!,
          label: `${invitedParent?.person?.firstName} ${invitedParent?.person?.lastName}`,
          ...invitedParent,
          roleId: invitedParent!.invitedFor![0].roleId!,
          permissions: Object.keys(invitedParent.metadata as object).includes(
            "permissions"
          )
            ? (invitedParent.metadata as object)["permissions"]
            : []
        };
      const addedParent = team.userRoles?.find(
        (player) => player?.user?.personId === parentId
      );
      if (addedParent)
        return {
          id: addedParent.user!.personId!,
          personId: addedParent.user?.person?.personId as string,
          value: addedParent.user!.personId!,
          label: `${addedParent?.user?.person?.firstName} ${addedParent?.user?.person?.lastName}`,
          ...addedParent,
          roleId: addedParent.roleId!,
          permissions: addedParent?.permissionOverrides
        };
    };

    let invites: PlayerOption[] = [],
      accepted: PlayerOption[] = [];
    if (addedPlayers) {
      accepted = addedPlayers.map((player) => {
        const isChild = player?.user?.person?.guardians?.length;
        const formattedPerson = {
          label: `${player?.user?.person?.firstName} ${player?.user?.person?.lastName}`,
          id: player!.user!.personId!,
          personId: player!.user!.personId!,
          value: player!.user!.personId!,
          gender: player?.user?.person?.gender,
          roleId: player.roleId!,
          ...player,
          permissions: player?.permissionOverrides,
          parent: isChild
            ? getParentObject(
                player?.user?.person?.guardians![0].guardian?.personId
              )
            : undefined,
          status: isChild ? "CHILD_ACCEPTED" : "USER_ACCEPTED"
        };
        return formattedPerson;
      });
      console.log("PLAYERS_ACCEPTED:", accepted);
    }
    if (selectedPlayersInvite) {
      invites = selectedPlayersInvite
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((player) => {
          const checkIfParentAccepted = (parentId) => {
            if (!addedPlayers || !addedPlayers.length) return false;
            const accepted = addedPlayers.findIndex(
              (player) => player!.user!.personId === parentId
            );
            if (accepted !== -1) return true;
            return false;
          };
          const isChild = player?.person?.guardians?.length;
          const formattedPerson = {
            label: `${player?.person?.firstName} ${player?.person?.lastName}`,
            id: player!.person!.personId!,
            value: player!.person!.personId!,
            gender: player?.person?.gender,
            roleId: player.invitedFor![0].roleId!,
            ...player,
            personId: player!.person!.personId!,
            status: isChild
              ? checkIfParentAccepted(
                  player?.person?.guardians![0].guardian?.personId
                )
                ? "PARENT_ACCEPTED"
                : "CHILD_PENDING"
              : "USER_PENDING",
            parent: isChild
              ? getParentObject(
                  player?.person?.guardians![0].guardian?.personId
                )
              : undefined,
            permissions: Object.keys(player.metadata as object).includes(
              "permissions"
            )
              ? ((player.metadata as object)["permissions"] as [])
              : []
          };
          return formattedPerson;
        });
      console.log("PLAYERS_INVITES:", invites);
    }
    return [...invites, ...accepted];
  };
  const populateUsers = () => {
    if (!team) return;
    const playersSelected = getPlayers();
    if (playersSelected?.length) {
      setPlayers([...playersSelected.map((player) => player.id)]);
      setPlayersWithValues([...playersSelected]);
    }
    const coachesSelected = getCoachesOrManagers("COACH");
    if (coachesSelected?.length) {
      setCoaches([...coachesSelected.map((coach) => coach.id)]);
      setCoachesWithValues([...coachesSelected]);
    }
    const managersSelected = getCoachesOrManagers("MANAGER");
    if (managersSelected) {
      setManagers([...managersSelected.map((manager) => manager.id)]);
      setManagerWithValues([...managersSelected]);
    }
  };

  console.log("IMAZ::", coaches, coachesWithValues);
  useEffect(() => {
    if (!team) return;
    if (team.sportId) setSportId(team.sportId);
    populateUsers();
    const defaultValues = {
      name: team.name,
      sportId: team.sportId,
      gender: team.gender,
      level: team.levelId,
      season: team.seasonId
    };
    setSeasonId(defaultValues.season);
    setSportId(defaultValues.sportId);
    reset(defaultValues);
  }, [team]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { options: seasonOptions, isLoading: seasonOptionsLoading } =
    useApiSelectOptions({
      api: useAdminSeasonGet,
      dataField: "results",
      labelField: "name",
      valueField: "seasonId",
      params: {
        sportId: sportId,
        organizationId: organizationId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  const { options: levelOptions, isLoading: levelOptionsLoading } =
    useApiSelectOptions({
      api: useAdminLevelGet,
      dataField: "levels",
      labelField: "name",
      valueField: "levelId",
      params: {
        sportId: sportId,
        organizationId: organizationId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  const { options: positionOptions } = useApiSelectOptions({
    api: useAdminPositionGet,
    dataField: "positions",
    labelField: "name",
    valueField: "positionId",
    params: {
      sportId: sportId
    },
    options: {
      query: {
        enabled: !!sportId
      }
    }
  });

  const [defaultPermissions, setDefaultPermissions] = useState<
    (ModelRole & {
      permissions: ModelRolePermission[];
    })[]
  >([]);

  const getTabs = () => {
    return ["Team Details", "Players", "Coaches", "Managers", "Permissions"];
  };
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const activeStepNumber = getTabs().findIndex((step) => step === tab);
  const onBackClick = () => {
    navigate("/teams");
  };

  const getBadgeCounts = () => {
    return [
      0,
      playersWithValues.length,
      coachesWithValues.length,
      managerWithValues.length,
      0
    ];
  };
  const coachSubRoles = useMemo(() => {
    const existingSubRoles =
      teamRoles.find((r) => r.alias === "COACH")?.children || [];
    const newOnes =
      subRoleOptions?.data?.roles?.filter(
        (subRole) =>
          !existingSubRoles.find((r) => r.inheritedFromId === subRole.roleId) &&
          subRole.alias === "COACH"
      ) || [];
    return [...existingSubRoles, ...(newOnes || [])];
  }, [subRoleOptions, teamRoles]);

  const managerSubRoles = useMemo(() => {
    const existingSubRoles =
      teamRoles.find((r) => r.alias === "MANAGER")?.children || [];
    const newOnes =
      subRoleOptions?.data?.roles?.filter(
        (subRole) =>
          !existingSubRoles.find((r) => r.inheritedFromId === subRole.roleId) &&
          subRole.alias === "MANAGER"
      ) || [];
    return [...existingSubRoles, ...(newOnes || [])];
  }, [subRoleOptions, teamRoles]);

  const { mutate: save, isLoading: isSaving } = useAdminTeamTeamIdPatch();
  const onSaveClick = async (formValues) => {
    try {
      const users = [
        ...playersWithValues,
        ...coachesWithValues,
        ...managerWithValues
      ];
      console.log("USERS!!!!!", users);
      const cleanedUsers = users.map((user) => cleanObject(user));
      const cleanedUsersTrimmed = cleanedUsers.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ user, ...rest }) => rest
      );
      const cleanDefaultPermissions = defaultPermissions
        .map((permission) => {
          delete permission.children;
          return cleanObject(permission);
        })
        .filter(
          (role) =>
            // if parent role is selected, allow
            // but if it's a child role, only allow if it has users
            !role.parentId ||
            (role.parentId &&
              cleanedUsers.some((user) => user.roleId === role.roleId))
        );

      const data = {
        name: formValues.name,
        sportId: sportId,
        levelId: formValues.level,
        gender: formValues.gender,
        seasonId: formValues.season,
        organizationId: organizationId,
        users: cleanedUsersTrimmed,
        defaultPermissions: cleanDefaultPermissions
      };
      console.log("DATA!!!!!");
      console.log(data);
      save(
        {
          teamId: teamId as string,
          data: data as TeamWithOrgCreateInput
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Team added successfully!", {
              variant: "success"
            });
            navigate("/teams");
          },
          onError: () => {
            enqueueSnackbar("Failed to edit team!", {
              variant: "error"
            });
          }
        }
      );
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error"
      });
      Sentry.captureException(e);
    }
  };

  const onConfirmCancel = (): void | Promise<void> => {
    navigate("/teams");
  };

  const onCancelCancel = (): void => {
    setIsConfirmationDialogOpen(false);
  };

  const getPrimaryAction = () => {
    if (activeStepNumber < getTabs().length - 1) {
      return () => {
        setTab(getTabs()[activeStepNumber + 1]);
      };
    } else {
      return handleSubmit(onSaveClick);
    }
  };

  return (
    <Container>
      <Toolbar
        title="Edit Team"
        backBtnClick={onBackClick}
        tabs={{
          tabs: getTabs(),
          onTabChange: onTabChange,
          activeTab: tab
        }}
        badgeCounts={getBadgeCounts()}
      />

      <Loader isLoading={isSportLoading || isFetchingTeam}>
        <Form>
          <Grid item container spacing={3}>
            {tab === "Team Details" && (
              <Grid item xs={12} data-testid="details_tab">
                <TeamDetailsForm
                  form={form}
                  disabled={false}
                  isEditing={true}
                  control={control}
                  isLoadingOptions={
                    isSportLoading &&
                    seasonOptionsLoading &&
                    levelOptionsLoading
                  }
                  sportOptions={sportOptions}
                  seasonOptions={seasonOptions}
                  levelOptions={levelOptions}
                  setSportsId={setSportId}
                  setSeasonId={setSeasonId}
                  playersWithValues={playersWithValues}
                />
              </Grid>
            )}
            {tab === "Players" && !playerUserOptionsLoading && (
              <Grid item xs={12} data-testid="players_tab">
                <PlayerSelectionForm
                  form={form}
                  seasonId={seasonId}
                  isEditing={true}
                  disabled={false}
                  isLoadingUsers={playerUserOptionsLoading}
                  userOptions={playerOptionsList}
                  setUserOptions={setPlayerOptionsList}
                  positionOptions={positionOptions || []}
                  players={players}
                  playersWithValues={playersWithValues}
                  setPlayersWithValues={setPlayersWithValues}
                  setPlayers={setPlayers}
                  roleId={
                    teamRoles.find((r) => r.alias === "PLAYER")
                      ?.roleId as string
                  }
                  parentRoleId={
                    teamRoles.find((r) => r.alias === "PARENT")
                      ?.roleId as string
                  }
                  sportId={sportId}
                />
              </Grid>
            )}
            {tab === "Coaches" && (
              <Grid item xs={12}>
                <CoachSelectionForm
                  form={form}
                  disabled={false}
                  isEditing={true}
                  isLoadingUsers={isFetchingTeam}
                  userOptions={coachOptionsList}
                  setUserOptions={setCoachOptionsList}
                  subRoleOptions={coachSubRoles || []}
                  coaches={coaches}
                  coachesWithValues={coachesWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setCoaches={setCoaches}
                  roleId={
                    teamRoles.find((r) => r.alias === "COACH")?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Managers" && !managerUserOptionsLoading && (
              <Grid item xs={12}>
                <ManagerSelectionForm
                  form={form}
                  isEditing={false}
                  isLoadingUsers={isFetchingTeam}
                  userOptions={managerOptionsList}
                  setUserOptions={setManagerOptionsList}
                  subRoleOptions={managerSubRoles || []}
                  managers={managers}
                  managersWithValues={managerWithValues}
                  setManagersWithValues={setManagerWithValues}
                  setManagers={setManagers}
                  roleId={
                    teamRoles.find((r) => r.alias === "MANAGER")
                      ?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Permissions" && (
              <Grid item xs={12}>
                <PermissionSelectionForm
                  form={form}
                  isEditing
                  defaultPermissions={defaultPermissions}
                  setDefaultPermissions={setDefaultPermissions}
                  playersWithValues={playersWithValues}
                  coachesWithValues={coachesWithValues}
                  players={players}
                  coaches={coaches}
                  managers={managers}
                  managerWithValues={managerWithValues}
                  setManagerWithValues={setManagerWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setPlayersWithValues={setPlayersWithValues}
                  playerRole={teamRoles.find((r) => r.alias === "PLAYER")!}
                  parentRole={teamRoles.find((r) => r.alias === "PARENT")!}
                  coachRole={teamRoles.find((r) => r.alias === "COACH")!}
                  coachSubRoles={coachSubRoles || []}
                  managerRole={teamRoles.find((r) => r.alias === "MANAGER")!}
                  managerSubRoles={managerSubRoles || []}
                  permissions={permissionsList}
                />
              </Grid>
            )}
          </Grid>
        </Form>
      </Loader>

      <Footer
        additionalBtns={[
          activeStepNumber > 0 ? (
            <Button
              variant="admin-secondary"
              type="button"
              onClick={() => {
                setTab(getTabs()[activeStepNumber - 1]);
                setTimeout(() => {
                  form.trigger();
                }, 50);
              }}
              key={Math.random().toString()}
            >
              Back
            </Button>
          ) : null,
          <Button
            variant="admin-secondary"
            type="button"
            onClick={() => {
              setIsConfirmationDialogOpen(true);
            }}
            key={Math.random().toString()}
          >
            Cancel
          </Button>,
          <Button
            variant="admin-primary"
            type="button"
            onClick={getPrimaryAction()}
            isLoading={activeStepNumber === getTabs().length - 1 && isSaving}
            disabled={!form.formState.isValid || !form.formState.isDirty}
            key={Math.random().toString()}
          >
            {activeStepNumber < getTabs().length - 1
              ? "Save & Continue"
              : "Save & Send Invites"}
          </Button>
        ]}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
    </Container>
  );
};
