import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import {
  ModelRole,
  ModelRolePermission,
  ModelTrainingProgram,
  RoleAlias,
  useAdminLevelGet,
  useAdminPermissionGet,
  useAdminPositionGet,
  useAdminRoleRoleIdUserGet,
  useAdminSportGet,
  useAdminTrainingProgramProgramIdDelete,
  useAdminTrainingProgramProgramIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  CoachOption,
  CoachSelectionForm
} from "@pages/teams-programs/components/CoachSelectionForm";
import {
  ManagerOption,
  ManagerSelectionForm
} from "@pages/teams-programs/components/ManagerSelectionForm";
import {
  PlayerOption,
  PlayerSelectionForm
} from "@pages/teams-programs/components/PlayerSelectionForm";
import { ProgramDetailsForm } from "./ProgramDetailsForm";
import { PermissionSelectionForm } from "@pages/teams-programs/components/PermissionSelectionForm";
import { TrainingProgramTypeOptions } from "@utils/constants";
import { useSnackbar } from "notistack";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { hasPermission } from "@services/Casbin";

export const ProgramsView = () => {
  const navigate = useNavigate();
  const { programId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [toDelete, setToDelete] = useState<ModelTrainingProgram | null>(null);
  const organizationId = useRecoilValue(organizationAtom);
  const [permissions, setPermissions] = useState({
    delete: false
  });

  if (!organizationId) {
    navigate("/organizations");
  }

  const [tab, setTab] = useState("Program Details");
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
  };
  const {
    data: programResponse,
    isFetching: isFetchingProgram,
    error: error
  } = useAdminTrainingProgramProgramIdGet(programId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  //let roleOptions = [] as ModelRole[];
  const [roleOptions, setRoleOptions] = useState<ModelRole[]>([]);
  const program = useMemo(() => {
    // roleOptions = programResponse?.data?.roles as ModelRole[];
    if (programResponse?.data?.roles)
      setRoleOptions([...programResponse.data.roles]);
    return programResponse?.data;
  }, [programResponse]);

  const form = useForm({
    mode: "onBlur"
  });
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminTrainingProgramProgramIdDelete();

  const onConfirmDelete = async () => {
    if (!toDelete?.programId) return;
    try {
      await deleteAsync({ programId: toDelete.programId });
      enqueueSnackbar("Training Program deleted successfully", {
        variant: "success"
      });
      setToDelete(null);
      navigate("/training-programs");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete the team.", {
        variant: "error"
      });
      setToDelete(null);
    }
  };

  const { reset, getValues } = form;

  const convertTimetoDate = (time: string) => {
    const dateString = "2023-12-13"; //can be any date because we only show time in timePicker
    const dateTimeString = `${dateString}T${time}`;
    return new Date(dateTimeString);
  };
  const generateDateTimeComponents = (dates) => {
    const dateTimeComponents = dates.flatMap((date) => {
      const dateAndTimeObject = {
        clearable: false,
        onlyTime: false,
        startDate: new Date(date.startDate),
        endDate: new Date(date.endDate),
        startTime:
          date.timings.length > 0
            ? convertTimetoDate(date.timings[0].startTime)
            : undefined,
        endTime:
          date.timings.length > 0
            ? convertTimetoDate(date.timings[0].endTime)
            : undefined,
        customRepeat: date.timings[0].customRepeat
      };
      const timingsObject =
        date.timings.length > 1
          ? date.timings.slice(1).map((timing) => ({
              clearable: false,
              onlyTime: true,
              startDate: undefined,
              endDate: undefined,
              startTime: convertTimetoDate(timing.startTime),
              endTime: convertTimetoDate(timing.endTime),
              customRepeat: timing.customRepeat
            }))
          : [];
      return [dateAndTimeObject, ...timingsObject];
    });
    return dateTimeComponents;
  };

  const getCoachesOrManagers = (alias) => {
    if (!program) return;
    const selectedPlayersInvite = program.invites?.filter(
      (item) => item.invitedFor![0]?.alias === alias
    );
    const addedPlayers = program.userRoles?.filter(
      (item) => item.role?.alias === alias
    );
    let invites: CoachOption[] = [],
      accepted: CoachOption[] = [];

    if (addedPlayers) {
      accepted = addedPlayers.map((player) => {
        const formattedPerson = {
          label: `${player?.user?.person?.firstName} ${player?.user?.person?.lastName}`,
          id: player!.user!.personId!,
          personId: player!.user!.personId!,
          value: player!.user!.personId!,
          roleId: player?.userRoleId,
          ...player,
          role: alias,
          permissions: player?.permissionOverrides,
          status: "ACTIVE"
        };
        return formattedPerson;
      });
      console.log("COACH?MANAGERS_ACCEPTED:", accepted);
    }
    if (selectedPlayersInvite) {
      invites = selectedPlayersInvite
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((player) => {
          const formattedPerson = {
            label: `${player?.person?.firstName} ${player?.person?.lastName}`,
            id: player!.person!.personId,
            value: player!.person!.personId,
            roleId: player?.invitedFor![0]?.roleId,
            ...player,
            role: alias,
            status: "PENDING"
          };
          return formattedPerson;
        });
      console.log("COACH/MANAGERS_INVITES:", invites);
    }
    return [...invites, ...accepted];
  };
  const getPlayers = () => {
    if (!program) return;
    const selectedPlayersInvite = program.invites?.filter(
      (item) => item.invitedFor![0]?.alias === "PLAYER"
    );
    const addedPlayers = program.userRoles?.filter(
      (item) => item.role?.alias === "PLAYER"
    );

    const getParentObject = (parentId) => {
      const invitedParent = program.invites?.find(
        (invite) => invite?.personId === parentId
      );
      if (invitedParent)
        return {
          id: invitedParent.personId,
          personId: invitedParent.personId,
          label: `${invitedParent?.person?.firstName} ${invitedParent?.person?.lastName}`,
          ...invitedParent,
          roleId: invitedParent!.invitedFor![0].roleId!
        };
      const addedParent = program.userRoles?.find(
        (player) => player?.user?.personId === parentId
      );
      if (addedParent)
        return {
          id: addedParent.user?.person?.personId,
          personId: addedParent.user?.person?.personId,
          label: `${addedParent?.user?.person?.firstName} ${addedParent?.user?.person?.lastName}`,
          ...addedParent,
          roleId: addedParent?.userRoleId,
          permissions: addedParent?.permissionOverrides
        };
    };

    let invites: PlayerOption[] = [],
      accepted: PlayerOption[] = [];
    if (addedPlayers) {
      accepted = addedPlayers.map((player) => {
        const isChild = player?.user?.person?.guardians?.length;
        const formattedPerson = {
          label: `${player?.user?.person?.firstName} ${player?.user?.person?.lastName}`,
          id: player!.user!.personId!,
          personId: player!.user!.personId!,
          value: player!.user!.personId!,
          gender: player?.user?.person?.gender,
          roleId: player?.userRoleId,
          ...player,
          permissions: player?.permissionOverrides,
          parent: isChild
            ? getParentObject(
                player?.user?.person?.guardians![0].guardian?.personId
              )
            : undefined,
          status: isChild ? "CHILD_ACCEPTED" : "USER_ACCEPTED"
        };
        return formattedPerson;
      });
      console.log("PLAYERS_ACCEPTED:", accepted);
    }
    if (selectedPlayersInvite) {
      invites = selectedPlayersInvite
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((player) => {
          const checkIfParentAccepted = (parentId) => {
            if (!addedPlayers || !addedPlayers.length) return false;
            const accepted = addedPlayers.findIndex(
              (player) => player!.user!.personId === parentId
            );
            if (accepted !== -1) return true;
            return false;
          };
          const isChild = player?.person?.guardians?.length;
          const formattedPerson = {
            label: `${player?.person?.firstName} ${player?.person?.lastName}`,
            id: player!.person!.personId,
            value: player!.person!.personId,
            gender: player?.person?.gender,
            roleId: player?.invitedFor![0]?.roleId,
            ...player,
            status: isChild
              ? checkIfParentAccepted(
                  player?.person?.guardians![0].guardian?.personId
                )
                ? "PARENT_ACCEPTED"
                : "CHILD_PENDING"
              : "USER_PENDING",
            parent: isChild
              ? getParentObject(
                  player?.person?.guardians![0].guardian?.personId
                )
              : undefined
          };
          return formattedPerson;
        });
      console.log("PLAYERS_INVITES:", invites);
    }
    return [...invites, ...accepted];
  };
  const populateUsers = () => {
    if (!program) return;
    const playersSelected = getPlayers();
    if (playersSelected) setPlayersWithValues([...playersSelected]);
    const coachesSelected = getCoachesOrManagers("COACH");
    if (coachesSelected) setCoachesWithValues([...coachesSelected]);
    const managersSelected = getCoachesOrManagers("MANAGER");
    if (managersSelected) setManagerWithValues([...managersSelected]);
  };
  const [generatedComponents, setGeneratedComponents] = useState(
    getValues().dateTimeComponents
  );
  useEffect(() => {
    if (!program) return;
    if (program.sportId) setSportId(program.sportId);
    populateUsers();
    const defaultValues = {
      name: program.name,
      type: program.type,
      sportId: program.sportId,
      gender: program.genders,
      level: program.levelId,
      dateTimeComponents: generateDateTimeComponents(program.dates)
    };
    setGeneratedComponents(defaultValues.dateTimeComponents);
    reset(defaultValues);
  }, [program]);

  const [sportId, setSportId] = useState<string | undefined>(undefined);
  const [players, setPlayers] = useState<string[]>([]);
  const [playersWithValues, setPlayersWithValues] = useState<PlayerOption[]>(
    []
  );
  const [coachesWithValues, setCoachesWithValues] = useState<CoachOption[]>([]);
  const [managerWithValues, setManagerWithValues] = useState<ManagerOption[]>(
    []
  );
  const [defaultPermissions, setDefaultPermissions] = useState<
    (ModelRole & {
      permissions: ModelRolePermission[];
    })[]
  >([]);

  const [coaches, setCoaches] = useState<string[]>([]);
  const [managers, setManagers] = useState<string[]>([]);

  const { data: permissionRequest } = useAdminPermissionGet();
  const permissionsList = useMemo(() => {
    if (permissionRequest?.data) {
      const children = permissionRequest.data.find(
        (permission) => permission.permissionId === "training-programs"
      )?.children;
      return (
        children?.filter(
          (permission) => permission.permissionId?.includes("associated")
        ) || []
      );
    }
    return [];
  }, [permissionRequest]);

  const { data: playerUserOptions, isLoading: playerUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.PLAYER, {
      // seasonId: seasonId,
      sportId: sportId,
      type: "TRAINING_PROGRAM",
      organizationId: organizationId
    });
  const { data: coachUserOptions, isLoading: coachUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.COACH, {
      organizationId: organizationId
    });
  const { data: managerUserOptions, isLoading: managerUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.MANAGER, {
      organizationId: organizationId
    });

  const coachesSubRole = useMemo(() => {
    return roleOptions
      ?.find((item) => item.alias === "COACH")
      ?.children?.map((item) => {
        return {
          ...item,
          label: item?.name,
          value: item?.roleId
        };
      });
  }, [roleOptions]);

  const managerSubRole = useMemo(() => {
    console.log("ROLE OPTIONS MANAGER", roleOptions);
    console.log(roleOptions);
    return roleOptions
      ?.find((item) => item.alias === "MANAGER")
      ?.children?.map((item) => {
        return {
          ...item,
          label: item?.name,
          value: item?.roleId
        };
      });
  }, [roleOptions]);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { options: levelOptions, isLoading: levelOptionsLoading } =
    useApiSelectOptions({
      api: useAdminLevelGet,
      dataField: "levels",
      labelField: "name",
      valueField: "levelId",
      params: {
        sportId: sportId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  const { options: positionOptions } = useApiSelectOptions({
    api: useAdminPositionGet,
    dataField: "positions",
    labelField: "name",
    valueField: "positionId",
    params: {
      sportId: sportId
    },
    options: {
      query: {
        enabled: !!sportId
      }
    }
  });

  const getTabs = () => {
    return [
      "Program Details",
      "Athletes",
      "Coaches",
      "Managers",
      "Permissions"
    ];
  };
  const onBackClick = () => {
    navigate("/training-programs");
  };
  const getBadgeCounts = () => {
    return [
      0,
      playersWithValues.length,
      coachesWithValues.length,
      managerWithValues.length,
      0
    ];
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission(
        "training-programs.training-programs",
        "DELETE"
      );
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  return (
    <Container>
      <Toolbar
        title="View Training Programs"
        editBtnClick={() => navigate(`/training-programs/${programId}/edit`)}
        backBtnClick={onBackClick}
        tabs={{
          tabs: getTabs(),
          onTabChange: onTabChange,
          activeTab: tab
        }}
        {...(playersWithValues.length == 0 &&
          permissions.delete && {
            deleteBtnClick: () => setToDelete(program!)
          })}
        badgeCounts={getBadgeCounts()}
      />
      <Form>
        <Loader isLoading={isSportLoading || isFetchingProgram}>
          <Grid container spacing={3}>
            {tab === "Program Details" && generatedComponents && (
              <ProgramDetailsForm
                disabled
                isEditing={false}
                form={form}
                isLoadingOptions={isSportLoading && levelOptionsLoading}
                sportOptions={sportOptions}
                levelOptions={levelOptions}
                typeOptions={TrainingProgramTypeOptions}
                setSportsId={setSportId}
                generatedComponents={generatedComponents}
              />
            )}
            {tab === "Athletes" && (
              <Grid xs={12}>
                <PlayerSelectionForm
                  type="program"
                  form={form}
                  disabled={true}
                  isEditing={false}
                  isLoadingUsers={playerUserOptionsLoading}
                  userOptions={playerUserOptions?.data || []}
                  positionOptions={positionOptions || []}
                  players={players}
                  playersWithValues={playersWithValues}
                  setPlayersWithValues={setPlayersWithValues}
                  setPlayers={setPlayers}
                  roleId={
                    roleOptions?.find((r) => r.alias === "PLAYER")
                      ?.roleId as string
                  }
                  parentRoleId={
                    roleOptions?.find((r) => r.alias === "PARENT")
                      ?.roleId as string
                  }
                  sportId={sportId}
                />
              </Grid>
            )}
            {tab === "Coaches" && (
              <Grid xs={12}>
                <CoachSelectionForm
                  form={form}
                  disabled={true}
                  isEditing={false}
                  isLoadingUsers={coachUserOptionsLoading}
                  userOptions={coachUserOptions?.data || []}
                  subRoleOptions={coachesSubRole || []}
                  coaches={coaches}
                  coachesWithValues={coachesWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setCoaches={setCoaches}
                  roleId={
                    roleOptions?.find((r) => r.alias === "COACH")
                      ?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Managers" && (
              <Grid xs={12}>
                <ManagerSelectionForm
                  form={form}
                  disabled={true}
                  isEditing={false}
                  isLoadingUsers={managerUserOptionsLoading}
                  userOptions={managerUserOptions?.data || []}
                  subRoleOptions={managerSubRole || []}
                  managers={managers}
                  managersWithValues={managerWithValues}
                  setManagersWithValues={setManagerWithValues}
                  setManagers={setManagers}
                  roleId={
                    roleOptions?.find((r) => r.alias === "MANAGER")
                      ?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Permissions" && (
              <Grid xs={12}>
                <PermissionSelectionForm
                  form={form}
                  disabled
                  defaultPermissions={defaultPermissions}
                  setDefaultPermissions={setDefaultPermissions}
                  playersWithValues={playersWithValues}
                  coachesWithValues={coachesWithValues}
                  managerWithValues={managerWithValues}
                  setManagerWithValues={setManagerWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setPlayersWithValues={setPlayersWithValues}
                  players={players}
                  coaches={coaches}
                  managers={managers}
                  playerRole={roleOptions!.find((r) => r.alias === "PLAYER")!}
                  parentRole={roleOptions!.find((r) => r.alias === "PARENT")!}
                  coachRole={roleOptions!.find((r) => r.alias === "COACH")!}
                  coachSubRoles={coachesSubRole || []}
                  managerRole={roleOptions!.find((r) => r.alias === "MANAGER")!}
                  managerSubRoles={managerSubRole || []}
                  permissions={permissionsList}
                />
              </Grid>
            )}
          </Grid>
        </Loader>
      </Form>
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Training Program"
        body={`Are you sure you want to delete ${toDelete?.name}?`}
        close={() => setToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Container>
  );
};
